
import './Header.css'
import PropTypes from 'prop-types';

function Header({scrolledHeader, handleLink}) {

    return (
        <header id='header' className={scrolledHeader ? 'scrolled-header' : ''}>
            <div className='header-top'>
                <div className='container'>
                    <ul className='top-items'>
                        <li>
                            <img src='/icons/2fd29c034b11ed3cc97e4d0928843b9a.svg' alt=''/>
                            <span>6000 Kecskemét, Kisfaludy utca 8.</span>
                        </li>
                        <li>
                            <img src='/icons/7d6cc54b80562adb170817079e2f888a.svg' alt=''/>
                            <span>+36 70 646 1848</span>
                        </li>
                        <li>
                            <img src='/icons/11d325f0c8c6905d300805eacd39740c.svg' alt=''/>
                            <span>info@tartozaskezeles.hu</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div className='header-menu'>
                <div className='container'>
                    <div className='flex-box'>
                        <div className='logo-container'>
                            <img src='/logos/tartozaskezeles_logo-white.png' alt='tartozaskezeles.hu kft.'
                                 className='logo'/>
                        </div>
                        <div className='menu-container'>
                            <ul className='menu'>
                                <li>
                                    <span className='home-button' onClick={() => handleLink('home')}><img
                                        src='/icons/home-btn.svg' alt=''/></span>
                                </li>
                                <li>
                                    <span onClick={() => handleLink('services')}>Szolgáltatások</span>
                                </li>
                                <li>
                                    <span onClick={() => handleLink('about')}>Rólunk</span>
                                </li>
                                <li>
                                    <span onClick={() => {
                                        handleLink('howto')
                                    }}>Követeléskezelés</span>
                                </li>
                                <li>
                                    <span className='contact-button'
                                          onClick={() => handleLink('contact')}>Kapcsolat</span>
                                </li>
                            </ul>

                            <div className='header-menu-small'>
                                <div className='hms-icon-container'>
                                    <div className='hms-icon-line'/>
                                    <div className='hms-icon-line'/>
                                    <div className='hms-icon-line'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='header-info-bar'>
                <div className='container'>
                <p>Felméréseink alapján a Magyarországon működő cégek 86 százaléka szenved kintlévőség kezelési
                        problémákkal. Amennyiben az Ön cége is ilyen problémákkal küzd keressen minket bizalommal,
                        követeléseit gyorsan és költségkímélő módon behajtjuk.</p>
                </div>
            </div>

            <div className='content'>
                <div className='container'>
                    <div className='content-container'>
                        <div className='content-info'>
                            <div className='line-1-container'>
                                <p className='line-1'>
                                    Kérjen tőlünk
                                </p>
                                <div className='line-1-effect' />
                            </div>
                            <p className='line-2'>
                            Követeléskezelési tanácsadást
                            </p>
                            <p className='line-3'>
                                Bízza ránk az ügyintézést
                            </p>
                        </div>
                        <div className='img-container'>
                            <img src='/images/office_lady-img@2x.png' alt=''/>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

Header.propTypes = {
    scrolledHeader: PropTypes.bool,
    handleLink: PropTypes.func
};

export default Header;