
import './Services.css'

function Services() {

    const accordions = document.getElementsByClassName("accordion");

    const handleAccordion = (event) => {

        for (let item of accordions) {
            item.classList.remove('active')
            item.nextElementSibling.style.display = "none"
        }

        const node = event.target
        node.classList.toggle('active')
        let panel = node.nextElementSibling
        if (panel.style.display === "block") {
            panel.style.display = "none";
        } else {
            panel.style.display = "block";
        }
    }

    return (
        <section id='services' className='services'>
            <div className='container'>
                <h1>Követeléskezelés</h1>
                <h2>Szolgáltatásaink</h2>

                <div className='services-container'>
                    <div className='left'>
                        <h3>Milyen esetekben tudunk cégének segíteni?</h3>
                        <div className='services-left-inside'>
                            <div className='list-content'>
                                <ul>
                                    <li>Partnere nem fizette meg kiállított számlája ellenértékét, csak ígérget?</li>
                                    <li>Teljesítésigazolásait visszatartják?</li>
                                    <li>Elszámolási vitába csöppent?</li>
                                    <li>Partnerével szemben indított végrehajtás/felszámolás sikertelen?</li>
                                    <li>Munkavállalójának/ismerősének kölcsönadott, de nem kapta vissza a pénzét?</li>
                                </ul>
                            </div>
                            <img src='/images/koveteleskezeles.svg' alt=''/>
                        </div>
                    </div>
                    <div className='right'>
                        <div className='accordion-container-1'>
                            <button className='accordion start active' onClick={handleAccordion}>Mit tartalmaz a
                                szolgáltatáscsomag?
                            </button>
                            <div className='panel' style={{display: 'block'}}>
                                <ul>
                                    <li>Korlátlan mennyiségű egyedi megbízás teljesítését, ami magába foglalja az
                                        alábbiakat:
                                    </li>
                                    <ul style={{marginLeft: '20px'}}>
                                        <li>Előzetes környezettanulmány</li>
                                        <li>Írásos-, telefonos-, személyes megkereséseket szakértő kollégáink által
                                        </li>
                                    </ul>
                                    <li>Éves rendelkezésre állást</li>
                                    <li>Szakértői követeléskezelési tanácsadást, igény esetén jogi tanácsadást</li>
                                    <li>Ügyvédi asszisztenciát</li>
                                    <li>Prevenciós tevékenységeket</li>
                                </ul>
                            </div>
                            <button className='accordion center' onClick={handleAccordion}>Előnyök</button>
                            <div className='panel'>
                                <ul>
                                    <li>
                                        Munkaerő megtakarítás: Nem kell a munkatársakat új feladattal megbízni vagy
                                        átcsoportosítani késedelmes rendezés esetén.
                                    </li>
                                    <li>
                                        Hatékonyság: A Tartozáskezelés.hu Kft szakemberei gyorsan és hatékonyan kezelik
                                        a követeléseket.
                                    </li>
                                </ul>
                            </div>
                            <button className='accordion end' onClick={handleAccordion}>Költségek</button>
                            <div className='panel end'>
                                <p>Amennyiben meghaladja a tőkekövetelés a 3.000.000 Ft-ot, akkor együtt alkudjuk ki a
                                    sikerdíj mértékét.</p>
                                <p>Vállalkozói jogviszonyból létrejött kintlévőségeknél jegybanki alapkamat + 8%
                                    számítható fel késedelmi kamat gyanánt, magánszemélyek vonatkozásában a jegybanki
                                    alapkamat.</p>
                                <p>Sok esetben megbízóink nem is tudják, hogy késedelmi kamatot számíthatnak fel
                                    követeléseikre. Az esetek döntő többségében a megbízók számára behajtott késedelmi
                                    kamat fedezni szokta a sikerdíjunkat, így megbízóink a teljes követelésüket
                                    visszakapják.</p>
                            </div>
                        </div>
                        <div className='accordion-container-2'>
                            <button className='accordion start' onClick={handleAccordion}>Magánszemélyeknek</button>
                            <div className='panel'>
                                <p>A magánszemélyek számára nyújtott szolgáltatásaink segítenek a kölcsönök megfelelő
                                    kezelésében és a megállapodások szerinti rendezésében. Ha kölcsön adott egy ismerősének,
                                    és az összeg visszafizetése akadályokba ütközött, szakértői segítséget kínálunk.
                                    Tapasztalt csapatunk hatékonyan kezeli a lakossági követeléseket és tartozásokat, hogy
                                    Ön mihamarabb visszakaphassa jogosan megillető pénzét.</p>
                            </div>
                            <button className='accordion center' onClick={handleAccordion}>Követeléskezelési Tanácsadás</button>
                            <div className='panel'>
                                <p>Ha Ön követelését szeretné érvényesíteni, vagy Önnel szemben léptek fel követelési igénnyel, szakértői segítségünkkel naprakész információkat kaphat. Szakértőink folyamatosan nyomon követik a végrehajtási törvények és jogszabályok változásait, így pontos válaszokat tudnak adni a teljes kintlévőségkezelési folyamatra vonatkozóan.</p>
                            </div>

                            <button className='accordion end' onClick={handleAccordion}>Prevenciós tevékenységek</button>
                            <div className='panel end'>
                                <ul>
                                    <li>Garantálja a cég védelmét, hiszen együttműködésünk belefoglalható a partnereikkel megkötött szerződésbe, ami már alapvetően fizetésre sarkallja az ügyfeleit</li>
                                    <li>Számláin online pecsét formájában feltüntethető</li>
                                    <li>Üzleti partnerek leinformálását, hogy Társaságunk védelmével és hátterével magabiztosabban tudjon terjeszkedni</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Services;