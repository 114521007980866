
import './App.css';
import Header from './sections/Header'
import Services from "./sections/Services";
import About from "./sections/About";
import HowTo from "./sections/HowTo";
import Contact from "./sections/Contact";
import Footer from "./sections/Footer";
import {useEffect, useState} from "react";

function App() {

    const [scrolledHeader, setScrolledHeader] = useState(false)

    const handleScroll = event => {
        const positionY = window.scrollY
        return (positionY >= 25) ? setScrolledHeader(true) : setScrolledHeader(false)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: true})

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, []);

    const handleLink = (s) => {
        const currentPosition = window.scrollY
        const sectionY = document.getElementById(s)?.offsetTop
        if (sectionY !== undefined) {
            window.scroll({top: ((currentPosition <=25) ? sectionY - 150 : sectionY - 75), behavior: 'smooth'});
        } else {
            window.scroll({top: 0, behavior: 'smooth'});

        }
    }


    return (
        <div className="app">
            <Header scrolledHeader={scrolledHeader} handleLink={handleLink} />
            <Services />
            <About />
            <HowTo />
            <Contact />
            <Footer handleLink={handleLink} />
        </div>
  );
}

export default App;
