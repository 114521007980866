
import './Contact.css'
import 'mapbox-gl/dist/mapbox-gl.css';
import {useEffect, useRef} from "react";
import mapboxgl from 'mapbox-gl';

function Contact() {

    const mapContainerRef = useRef();
    const mapRef = useRef();

    useEffect(() => {
        mapboxgl.accessToken = 'pk.eyJ1IjoiZmVrdXNzIiwiYSI6ImNsemlxdTBlNjBreXkyanNhMmZydzg5ZDQifQ.gNoPuiUXRWTJePKi01eTQw';

        mapRef.current = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/streets-v12',
            center: [19.690895696524745, 46.905021722590156],
            zoom: 16
        });

        new mapboxgl.Marker({color: '#17193AB2'})
            .setLngLat([19.690895696524745, 46.905021722590156])
            .addTo(mapRef.current);

        return () => mapRef.current;
    }, []);

    const contactInfos = [
        {icon: '/images/2fd29c034b11ed3cc97e4d0928843b9a.svg', info: '6000 Kecskemét, Kisfaludy utca 8.'},
        {icon: '/images/11d325f0c8c6905d300805eacd39740c.svg', info: 'info@tartozaskezeles.hu'},
        {icon: '', info: 'break'},
        {icon: '/images/a47ac3ccd1557f7fbdb769f1c535b2b9.svg', info: 'H-P: 10:00-16:00'},
        {icon: '/images/7d6cc54b80562adb170817079e2f888a.svg', info: '+36 70 646 1848'},
    ]


    return (
        <section id='contact' className='contact'>
            <div className='container'>
                <div className='contact-title-container'>
                    <div className='call-back'>
                        <a href='/'>Visszahívás kérése</a>
                    </div>
                    <h2>Elérhetőségeink</h2>
                    <div className='send-message'>
                        <a href='/'>Üzenet küldése</a>
                    </div>
                </div>
                <p>Forduljon hozzánk bizalommal, és dolgozzunk együtt a megoldásért! Hivjon minket telefonon vagy
                    küldjön e-mailt, és mi garantáljuk, hogy mindent megteszünk az Ön sikeréért.</p>

                <div className='contacts-info-container'>
                    {
                        contactInfos.map((item, index) => {
                            if (item.info === 'break') {
                                return (
                                    <div className='contact-info-break' key={index} />
                                )
                            } else {
                                return (
                                    <div className='contact-info' key={index}>
                                        <img src={item?.icon} alt=''/>
                                        <h3>{item?.info}</h3>
                                    </div>
                                )
                            }
                        })
                    }
                </div>

                <div className='map-container'>
                    <div id="map" ref={mapContainerRef}
                         style={{ width: '100%', height: '300px', borderRadius: '20px' }}></div>;
                </div>

            </div>
        </section>
    )
}

export default Contact;