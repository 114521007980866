
import './About.css'

function About() {

    return (
        <section id='about' className='about'>
            <div className='container'>

                <div className='about-container'>
                    <div className='content-container'>
                        <h2>Amit érdemes tudni rólunk</h2>
                        <p>Cégünk 2013-ban alakult, azóta pedig az egyik legnagyobb szereplővé nőtte ki magát a
                            hazai követeléskezelési piacon. A <span className='highlight'>Tartozáskezelés.hu Kft.</span> az ügyfeleinek
                            professzionális és hatékony követeléskezelést kínál. Tapasztalt csapatunk kiemelt
                            figyelmet fordít az ügyfelek igényeinek felmérésére és a személyre szabott megoldások
                            kidolgozására.</p>
                        <p>A cégünk alapvető értékei között szerepel a szakértelem, az átláthatóság és a
                            hatékonyság, amelyek mentén működünk nap mint nap.</p>
                        <div className='button-container'>
                            {/*<div className='read-more'>
                                <a href='/'>Tovább olvasom</a>
                            </div>*/}
                            <div className='call-back'>
                                <span >Visszahívás kérése</span>
                            </div>
                        </div>
                    </div>

                    <div className='image-container'>
                        <img src='/images/group-img.png' alt=''/>
                    </div>
                </div>

                <h2 className='counter-title'>Sikereink számokban</h2>
                <ul className='counter-container'>
                    <ul className='counter-inner-container'>
                        <li>
                            <p className='counter'>100+</p>
                            <p className='title'>Folyamatban lévő ügy</p>
                        </li>
                        <li>
                            <p className='counter'>20+</p>
                            <p className='title'>Éves szakmai tapasztalat</p>
                        </li>
                    </ul>
                    <ul className='counter-inner-container'>
                        <li>
                            <p className='counter'>91%</p>
                            <p className='title'>Sikerességi arány</p>
                        </li>
                        <li>
                            <p className='counter'>50+</p>
                            <p className='title'>Elégedett ügyfél</p>
                        </li>
                    </ul>
                </ul>

            </div>
        </section>
    )
}

export default About;