
import './Footer.css'
import PropTypes from "prop-types";

function Footer({handleLink}) {

    return(
        <section id='footer' className='footer'>
            <div className='container'>
                <div className='link-container'>
                    <div className='link-section'>
                        <h3>Menü</h3>
                        <ul>
                            <li><span className='link' onClick={() => handleLink('services')}>Szolgáltatások</span></li>
                            <li><span className='link' onClick={() => handleLink('about')}>Rólunk</span></li>
                            <li><span className='link' onClick={() => handleLink('howto')}>Követeléskezelés</span></li>
                            <li><span className='link' onClick={() => handleLink('contact')}>Elérhetőségek</span></li>
                        </ul>
                    </div>
                    <div className='link-section'>
                        <h3>Elérhetőségek</h3>
                        <ul>
                            <li>Tartozáskezelés.hu Kft.</li>
                            <li>Cím: <span className='blue'>6000 Kecskemét, Kisfaludy utca 8.</span></li>
                            <li>Telefon: <span className='blue'>+36 70 646 1848</span></li>
                            <li>E-mail: <span className='blue'>info@tartozaskezeles.hu</span></li>
                        </ul>
                    </div>
                    <div className='link-section'>
                        <h3>Dokumentumok</h3>
                        <ul>
                            <li><span className='link'>Adatvédelmi tájékoztató</span></li>
                            <li><span className='link'>Impresszum</span></li>
                        </ul>
                    </div>
                </div>

                <div className='logo-container'>
                    <img src='/logos/tartozaskezeles_logo-white.png' alt=''/>
                </div>
                <p className='copy-right'>©2024 Tartozáskezelés.hu Kft.</p>
            </div>
        </section>
    )
}

Footer.propTypes = {
    handleLink: PropTypes.func
};

export default Footer;