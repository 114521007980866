
import './HowTo.css'

function HowTo() {

    const howToItems = [
        {image: '/images/Group14.svg', title: 'Ügyfélkapcsolat felvétele', text: 'Ügyfélkapcsolati menedzserünk felkeresi Önt az együttműködés lehetőségeiről és az Ön igényeinek megismerése céljából.'},
        {image: '/images/Group16.svg', title: 'Adós azonosítása és elemzése', text: 'Az adóssal kapcsolatos részletek és dokumentumok begyűjtése. Az adós pénzügyi helyzetének elemzése és értékelése.'},
        {image: '/images/Group18.svg', title: 'Stratégia kidolgozása', text: 'Személyre szabott követeléskezelési stratégia kidolgozása az adós helyzetének és az Ön céljainak figyelembevételével.'},
        {image: '/images/Group20.svg', title: 'Követeléskezelés megkezdése', text: 'Követelési levelek kiküldése és telefonos/személyes kommunikáció az adóssal. Jogos lépések megtétele, ha szükséges (pl. jogi felszólítás, peres eljárás).'},
        {image: '/images/Group22.svg', title: 'Folyamatos nyomon követés', text: 'Rendszeres riportok készítése az ügy aktuális állapotáról és a folyamatban lévő intézkedésekről. Folyamatos kommunikáció Önnel az ügyfelekkel kapcsolatban.'},
        {image: '/images/Group24.svg', title: 'Eredmények és lezárás', text: 'A követelés rendezésének eredményeinek elemzése és értékelése. Az ügy lezárása és a végső dokumentáció elkészítése.'},
    ]

    return (
        <section id='howto' className='howto'>
            <div className='container'>

                <h2>Hogyan működik a követeléskezelés és a tartozás rendezése?</h2>
                <p>Íme egy folyamatábra arról, hogyan működik a követelés és tartozás rendezése a <span
                    className='highlight'>Tartozáskezelés.hu Kft.</span>-nél:</p>

                <div className='howto-items-container'>
                    {howToItems.map((item, index) => {
                        return (
                            <div className='howto-item' key={index}>
                                <div className='graphics'>
                                    <img src={item?.image} alt=''/>
                                </div>
                                <div className='text'>
                                    <h3>{item?.title}</h3>
                                    <p>{item?.text}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>

            </div>
        </section>
    )
}

export default HowTo;